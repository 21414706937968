import React from 'react'
import './Landing.scss'
import Navbar from '../Navbar/Navbar'
export default function LadingPage() {
    return (
        <>
            <Navbar />
            <div className='landingPage'>
                <div>
                    <div className='blackling'></div>
                    <div className='headers'>Digitalize Your Professional Services Business</div>
                    <div className='para'>Automate your practice and empower your team with our cloud-based suite.</div>
                    <div className='lablelist'>
                        <div className='text'
                            style={{
                                background: "black",
                                color: "white"
                            }}
                        >Consultants</div>
                        <div className='text'>Agencies</div>
                        <div className='text'>Enterprise</div>
                    </div>
                </div>
            </div>

            <div className='secondSecion'>
                <div className='inner'>
                    <div className='text'>Unlock the power of online <br />consultation with <span>TheFirm</span></div>
                    <div className='carditem'>
                        <div className='innerlist'>
                            <div className='listHeader'>Client Ops</div>
                            <div className='para'>AI-powered marketing software that helps you generate leads and automate marketing.</div>
                            <div className='liner'></div>
                            <div className='Features'>Popular Features</div>
                            <div className='flist'>Ai-powered lead generation</div>
                            <div className='flist'>Marketing automation</div>
                            <div className='flist'>Analytics</div>
                            <div className='learn'>Learn More</div>
                        </div>
                        <div className='innerlist'>
                            <div className='listHeader'>StrategyOS</div>
                            <div className='para'>AI-powered marketing software that helps you generate leads and automate marketing.</div>
                            <div className='liner'></div>
                            <div className='Features'>Popular Features</div>
                            <div className='flist'>Ai-powered lead generation</div>
                            <div className='flist'>Marketing automation</div>
                            <div className='flist'>Analytics</div>
                            <div className='learn'>Learn More</div>
                        </div>
                        <div className='innerlist'>
                            <div className='listHeader'>Outsource</div>
                            <div className='para'>AI-powered marketing software that helps you generate leads and automate marketing.</div>
                            <div className='liner'></div>
                            <div className='Features'>Popular Features</div>
                            <div className='flist'>Ai-powered lead generation</div>
                            <div className='flist'>Marketing automation</div>
                            <div className='flist'>Analytics</div>
                            <div className='learn'>Learn More</div>
                        </div>
                        <div className='innerlist'>
                            <div className='listHeader'>Client Ops</div>
                            <div className='para'>AI-powered marketing software that helps you generate leads and automate marketing.</div>
                            <div className='liner'></div>
                            <div className='Features'>Popular Features</div>
                            <div className='flist'>Ai-powered lead generation</div>
                            <div className='flist'>Marketing automation</div>
                            <div className='flist'>Analytics</div>
                            <div className='learn'>Learn More</div>
                        </div>
                        <div className='innerlist'>
                            <div className='listHeader'>StrategyOS</div>
                            <div className='para'>AI-powered marketing software that helps you generate leads and automate marketing.</div>
                            <div className='liner'></div>
                            <div className='Features'>Popular Features</div>
                            <div className='flist'>Ai-powered lead generation</div>
                            <div className='flist'>Marketing automation</div>
                            <div className='flist'>Analytics</div>
                            <div className='learn'>Learn More</div>
                        </div>
                        <div className='innerlist'>
                            <div className='listHeader'>Outsource</div>
                            <div className='para'>AI-powered marketing software that helps you generate leads and automate marketing.</div>
                            <div className='liner'></div>
                            <div className='Features'>Popular Features</div>
                            <div className='flist'>Ai-powered lead generation</div>
                            <div className='flist'>Marketing automation</div>
                            <div className='flist'>Analytics</div>
                            <div className='learn'>Learn More</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
